@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply text-gray-400 bg-black transition-all ease-in-out duration-300 rounded-sm outline-none;
  }

  [class*='cm-'],
  [class*='cm-'] * {
    @apply transition-none rounded-none bg-transparent font-mono;
  }

  a {
    @apply hover:text-white;
  }
}
